export const detectCSVSeparator = (line, separators = [',', ';']) => {
  let insideQuotes = false
  let currentSeparator = null

  for (let i = 0; i < line.length; i++) {
    const char = line[i]

    if (char === '"' && !insideQuotes) {
      // Opening an escape field
      insideQuotes = true
    } else if (char === '"' && line[i + 1] === '"' && insideQuotes) {
      // Handle double quotes inside an escaped field (`""` to represent a single `"`)
      i++
    } else if (char === '"' && insideQuotes) {
      // Closing an escaped field
      insideQuotes = false
    } else if (separators.includes(char) && !insideQuotes) {
      if (currentSeparator === null) {
        currentSeparator = char
      } else if (currentSeparator !== char) {
        // If we encounter a different separator (e.g., in "foo,bar;toto"),
        // and none of them are within double quotes, return the new separator.
        return char
      }
    }
  }

  return currentSeparator !== null ? currentSeparator : ','
}

export const parseCSVLine = (line, separator = ',') => {
  const fields = []
  let field = ''
  let insideQuotes = false

  for (let i = 0; i < line.length; i++) {
    const char = line[i]

    if (char === '"' && !insideQuotes) {
      // Opening an escape field
      insideQuotes = true
    } else if (char === '"' && line[i + 1] === '"' && insideQuotes) {
      // Handle double quotes inside an escaped field (`""` to represent a single `"`)
      field += '"'
      i++
    } else if (char === '"' && insideQuotes) {
      // Closing an escaped field
      insideQuotes = false
    } else if (char === separator && !insideQuotes) {
      // Add the current field to the list when we reach the separator
      fields.push(field.trim())
      field = '' // Reset the current field
    } else {
      // Add the character to the current field
      field += char
    }
  }

  // Add the last field after the line is processed
  fields.push(field.trim())

  return fields
}

export const parseCSV = (raw) => {
  const lines = raw.split(/\r?\n/)
  const separator = detectCSVSeparator(lines[0])

  const data = []
  for (const line of lines) {
    if (line.length === 0) {
      continue
    }

    data.push(parseCSVLine(line, separator))
  }

  return data
}

export const formatCSV = (lines, separator = ',') => {
  return lines
    .map((line) => {
      return line
        .map((v) => {
          if (v === null || v === undefined) {
            return ''
          }

          if (typeof v !== 'string') {
            return '' + v
          }

          const hasDoubleQuote = v.includes('"')
          const hasSeparator = v.includes(separator)

          if (!hasDoubleQuote && !hasSeparator) {
            return v
          }

          if (hasDoubleQuote) {
            v = v.replaceAll('"', '""')
          }

          return '"' + v + '"'
        })
        .join(separator)
    })
    .join('\r\n')
}
