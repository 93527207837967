<template>
  <o-upload
    v-model="csvFile"
    :loading="loading"
    :label="label"
    :type="fileType"
    @submit="submit"
    @deleteFile="csvFile = null"
  />
</template>

<script>
import OUpload from '@components/Upload.vue'
import { parseCSV } from '@helpers/csv'

export default {
  name: 'ImportCsv',

  components: {
    OUpload,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    format: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      csvFile: null,
      fileType: 'text/csv, application/vnd.ms-excel',
    }
  },

  methods: {
    checkHeaders(headers, expected) {
      const missingHeader = expected.find((v) => !headers.includes(v))
      if (missingHeader) {
        return `Colonne "${missingHeader}" manquante`
      }

      const wrongHeader = expected.find((v, i) => v !== headers[i])
      if (wrongHeader) {
        return `Colonne "${wrongHeader}" est à la mauvaise place`
      }
      return null
    },
    submit() {
      const reader = new FileReader()
      reader.onload = () => {
        const data = parseCSV(reader.result)

        const headers = data.shift()
        const expectedHeaders = Object.values(this.format)
        const error = this.checkHeaders(headers, expectedHeaders)
        if (error !== null) {
          this.$emit('submit', { result: null, error })
          return
        }

        this.$emit('submit', { result: this.formatData(data), error: null })
      }
      reader.readAsText(this.csvFile)

      this.csvFile = null
    },

    formatData(data) {
      const keys = Object.keys(this.format)

      return data.map((values) => {
        let item = {}
        for (let i = 0; i < keys.length; i++) {
          item[keys[i]] = values[i]
        }
        return item
      })
    },
  },
}
</script>
