const CATEGORY_NOT_FOUND = 'category-not-found'
const TRACKER_NOT_FOUND = 'tracker-not-found'
const TRACKER_UNAVAILABLE = 'tracker-unavailable'
const IS_REQUIRED = 'is-required'
const NOT_IN = 'not-in'
const INVALID_ARRAY = 'invalid-array'
const INVALID_OBJECT = 'invalid-object'
const IMPORT_FAILED = 'import-failed'
const CONFLICT = 'conflict'

const START_LINE_INDEX = 1

export const importLiveVehicleError = {
  liveVehicles: {
    [INVALID_ARRAY]: {
      [INVALID_OBJECT]: {
        [IS_REQUIRED]: {
          name: (line) => `Ligne ${line}: Nom requis`,
          number: (line) => `Ligne ${line}: Dossard requis`,
          liveCategoryName: (line) => `Ligne ${line}: Categorie requise`,
          accessType: (line) => `Ligne ${line}: Type d'accès (public/private) requis`,
          trackerNumber: (line) => `Ligne ${line}: Numéro de balise requis`,
          trackerType: (line) => `Ligne ${line}: Type de balise requis`,
        },
        [NOT_IN]: {
          trackerType: (line, name) => `Ligne ${line}: Type de balise ${name} inconnu`,
          accessType: (line, name) => `Ligne ${line}: Type d'accès ${name} inconnu`,
          gender: (line, name) => `Ligne ${line}: Type de genre ${name} inconnu`,
        },
      },
    },
    [IMPORT_FAILED]: {
      [CATEGORY_NOT_FOUND]: (line, name) => `Ligne ${line}: Categorie ${name} introuvable`,
      [TRACKER_NOT_FOUND]: (line, name) => `Ligne ${line}: Balise ${name} introuvable`,
      [TRACKER_UNAVAILABLE]: (line, name) => `Ligne ${line}: Balise ${name} non disponible`,
    },
    [CONFLICT]: 'Le live contient déjà des participants',
  },
}

export const importOwnerSpotTrackerError = {
  spotTrackers: {
    [INVALID_ARRAY]: {
      [INVALID_OBJECT]: {
        [IS_REQUIRED]: {
          number: (line) => `Ligne ${line}: Numéro requis`,
          owner: (line) => `Ligne ${line}: Propriétaire de balise requis`,
        },
        [NOT_IN]: {
          trackerType: (line, name) => `Ligne ${line}: Type de balise ${name} inconnue`,
          owner: (line, name) => `Ligne ${line}: Propriétaire ${name} inconnu`,
        },
      },
    },
    [IMPORT_FAILED]: {
      [TRACKER_NOT_FOUND]: (line, name) => `Ligne ${line}: Balise ${name} introuvable`,
    },
  },
}

export const importOwnerQueclinkTrackerError = {
  queclinkTrackers: {
    [INVALID_ARRAY]: {
      [INVALID_OBJECT]: {
        [IS_REQUIRED]: {
          number: (line) => `Ligne ${line}: Numéro requis`,
          owner: (line) => `Ligne ${line}: Propriétaire de balise requis`,
        },
        [NOT_IN]: {
          trackerType: (line, name) => `Ligne ${line}: Type de balise ${name} inconnue`,
          owner: (line, name) => `Ligne ${line}: Propriétaire ${name} inconnu`,
        },
      },
    },
    [IMPORT_FAILED]: {
      [TRACKER_NOT_FOUND]: (line, name) => `Ligne ${line}: Balise ${name} introuvable`,
    },
  },
}

export const getErrorMessages = (err, description) => {
  let errorMessages = []
  const keys = Object.keys(description)

  keys.forEach((key) => {
    if (err.details?.[key]) {
      const t = err.details[key].type
      const args = err.details[key].args

      args.forEach((argument) => {
        const arg = argument.args
        const type = argument.type
        const line = argument.index + START_LINE_INDEX

        if (
          arg.type === CATEGORY_NOT_FOUND ||
          arg.type === TRACKER_NOT_FOUND ||
          arg.type === TRACKER_UNAVAILABLE
        ) {
          const value = arg.args.value
          errorMessages.push(description[key][t][arg.type](line, value))
        }

        if (type === INVALID_OBJECT) {
          const names = Object.keys(arg)

          names.forEach((name) => {
            if (arg[name].type === NOT_IN) {
              const { value } = arg[name].args
              errorMessages.push(description[key][t][type][arg[name].type][name](line, value))
            }

            if (arg[name].type === IS_REQUIRED) {
              errorMessages.push(description[key][t][type][arg[name].type][name](line))
            }
          })
        }

        return ['Une erreur interne est survenue.']
      })
    }

    if (err.type === CONFLICT) {
      return [description[key][err.type]]
    }
  })

  return errorMessages
}
